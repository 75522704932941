import React from "react";
import styled from "styled-components";
import HeroBG from "../../assets/bg-img.jpg";

const Experiences = () => {
  return (
    <WorkHistory>
      <div className="overlay"></div>
      <div className="experience-container">
        <div className="headingdiv">
          <h3 className="heading" data-aos="fade-up">
            Experiences
          </h3>
        </div>
        <br />

        <div className="experiences">
          {/* One Experience */}
          <div
            className="experienceCard"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div className="title">
              <h4>Web Designer || Web Developer || Graphics Designer</h4>
            </div>
            <div className="date">
              <p>2018 - 2020</p>
            </div>
            <div className="company">
              <p>
                <i>Fobe Network</i>
              </p>
            </div>
            <div className="info">
              <ul>
                <li>
                  Designed Websites with Photoshop, Blogger and Joomla template.
                </li>
                <li>
                  Part of a five member team incharge of maintening company`s`
                  website and developing web applications for the company`s
                  client.
                </li>
                <li>
                  Took part in developing the User Interfaces of every
                  application built by the company and also ensures that the web
                  banners are 100% on point, and they also appeals to both our
                  clients and their customers.
                </li>
                <li>
                  Lead in the design, development, and implementation of the
                  graphic, layout, and production communication materials.
                </li>
                <li>
                  Supervise the assessment of all graphic materials in order to
                  ensure quality and accuracy of the design.
                </li>
                <li>
                  Oversee the efficient use of production project budgets.
                </li>
              </ul>
            </div>
          </div>

          {/* SECOND EXPERIENCE */}
          <div
            className="experienceCard"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div className="title">
              <h4>Front End Web Developer</h4>
            </div>
            <div className="date">
              <p>2020 - 2021</p>
            </div>
            <div className="company">
              <p>
                <i>Oxy Technology</i>
              </p>
            </div>
            <div className="info">
              <ul>
                <li>
                  Developed Responsive User Interface for inventory management
                  systems using React Js and Optimized web pages for improved
                  performance, speed, and cross-browser compatibility.
                </li>
                <li>
                  Collaborated with different backend developers from other
                  teams and consuming APIs made with both .NET and Node Js.
                </li>
                <li>
                  Participated as a junior dev in building applications with
                  ASP.NET MVC and Express Js(node js).
                </li>
                <li>
                  Collaborated with UI/UX designers since i have a solid
                  graphics design foundation.
                </li>
                <li>
                  Conducted code reviews and provided constructive feedback to
                  team members and Resolved front-end bugs and issues reported
                  by users or identified during testing.
                </li>
                <li>
                  Successfully completed a high-profile web development project
                  on time and within budget.
                </li>
              </ul>
            </div>
          </div>

          {/* THIRD EXPERIENCE */}
          <div
            className="experienceCard"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div className="title">
              <h4>FULL STACK WEB DEVELOPER</h4>
            </div>
            <div className="date">
              <p>2021 - 2023</p>
            </div>
            <div className="company">
              <p>
                <i>Obilomania & Contracts</i>
              </p>
            </div>
            <div className="info">
              <ul>
                <li>
                  Started my own Website agency and collaborated with other
                  developers in building projects on contract basis.
                </li>
                <li>
                  Collaborated with different backend developers from teams and
                  organizations, consuming APIs made with both .NET and Node Js.
                </li>
                <li>
                  Participated in building applications with ASP.NET MVC and
                  Express Js(node js).
                </li>
                <li>
                  Collaborated with UI/UX designers since i have a solid
                  graphics design foundation.
                </li>
                <li>
                  Conducted code reviews and provided constructive feedback to
                  team members and Resolved front-end bugs and issues reported
                  by users or identified during testing.
                </li>
                <li>
                  Successfully completed a high-profile web development project
                  on time and within budget.
                </li>
                <li>
                  Have constant and direct conversations with clients on how to
                  develop their applications and tailor it perfectly for their
                  business needs.
                </li>
              </ul>
            </div>
          </div>
          {/* FOURTH EXPERIENCE */}
          <div
            className="experienceCard"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div className="title">
              <h4>React and .Net Developer</h4>
            </div>
            <div className="date">
              <p>Mar 2023 - Present</p>
            </div>
            <div className="company">
              <p>
                <i>Side Phrase</i>
              </p>
            </div>
            <div className="info">
              <ul>
                <li>
                  Used React Js to develop User Interfaces designed with figma
                </li>
                <li>
                  Consumed API with multiple end points made with both Node Js
                  and .Net (e.g e-commerce, store and inventory management
                  systems)
                </li>
                <li>
                  Manage states on web apps using Redux and Optimizing image
                  load time by implementing Lazy loading
                </li>
                <li>
                  Participated in building applications with ASP.NET MVC and
                  Express Js(node js).
                </li>
                <li>
                  Conducted code reviews and provided constructive feedback to
                  team members and Resolved front-end bugs and issues reported
                  by users or identified during testing.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </WorkHistory>
  );
};

const WorkHistory = styled.div`
  width: 100%;
  height: fit-content;
  overflow: hidden;
  position: relative;
  background-image: url(${HeroBG});
  padding: 2rem 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
  }
  .experience-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 10;
    font-family: "Jost", system-ui;
    padding: 2rem;
    width: 75%;
    box-shadow: none;
    border-radius: 0.5rem;
  }
  .headingdiv {
    width: fit-content;
    overflow: hidden;
  }
  .experiences {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1.5rem;
    width: 100%;
  }
  .experienceCard {
    width: 48.5%;
    height: 52vh;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: start;
    background: rgba(255, 255, 255, 0.2);

    padding: 1rem 2rem;
    border-radius: 1rem;
    overflow: hidden;
    scroll-behavior: visible;
    box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
      5px 5px 5px 4px rgba(2, 2, 2, 0.15);
    color: wheat;
  }
  .experienceCard .title h4 {
    font-size: 1.2rem;
    font-weight: 500;
    font-weight: 600;
  }
  .experienceCard .date p {
    font-size: 0.8rem;
    padding: 0.2rem 1rem;
  }
  .experienceCard .company p {
    font-weight: 700;
    border-bottom: 1px solid wheat;
  }
  .experienceCard .info ul li {
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 0.5rem;
  }
  @media screen and (max-width: 1300px) {
    width: 100%;
    height: fit-content;
    overflow: hidden;
    position: relative;
    background-image: url(${HeroBG});
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .experience-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 10;
      font-family: "Jost", system-ui;
      padding: 2rem;
      width: 100%;
      box-shadow: none;
      border-radius: 0.5rem;
    }
    .headingdiv {
      width: fit-content;
      overflow: hidden;
    }
    .experiences {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1.5rem;
      width: 100%;
    }
    .experienceCard {
      width: 48.5%;
      min-height: 62vh;
      height: fit-content;
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      align-items: start;
      background: rgba(255, 255, 255, 0.2);

      padding: 1rem 2rem;
      border-radius: 1rem;
      overflow: hidden;
      scroll-behavior: visible;
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      color: wheat;
    }
    .experienceCard .title h4 {
      font-size: 1.2rem;
      font-weight: 500;
      font-weight: 600;
    }
    .experienceCard .date p {
      font-size: 0.8rem;
      padding: 0.2rem 1rem;
    }
    .experienceCard .company p {
      font-weight: 700;
      border-bottom: 1px solid wheat;
    }
    .experienceCard .info ul li {
      font-size: 1rem;
      line-height: 1.3rem;
      margin-bottom: 0.5rem;
    }
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: fit-content;
    overflow: hidden;
    position: relative;
    background-image: url(${HeroBG});
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .experience-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 10;
      font-family: "Jost", system-ui;
      padding: 2rem;
      width: 100%;
      box-shadow: none;
      border-radius: 0.5rem;
    }
    .headingdiv {
      width: fit-content;
      overflow: hidden;
    }
    .experiences {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1.5rem;
      width: 100%;
    }
    .experienceCard {
      width: 48.5%;
      min-height: 75vh;
      height: fit-content;
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      align-items: start;
      background: rgba(255, 255, 255, 0.2);

      padding: 1rem 2rem;
      border-radius: 1rem;
      overflow: hidden;
      scroll-behavior: visible;
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      color: wheat;
    }
    .experienceCard .title h4 {
      font-size: 1.2rem;
      font-weight: 500;
      font-weight: 600;
    }
    .experienceCard .date p {
      font-size: 0.8rem;
      padding: 0.2rem 1rem;
    }
    .experienceCard .company p {
      font-weight: 700;
      border-bottom: 1px solid wheat;
    }
    .experienceCard .info ul li {
      font-size: 1rem;
      line-height: 1.3rem;
      margin-bottom: 0.5rem;
    }
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    height: fit-content;
    overflow: hidden;
    position: relative;
    background-image: url(${HeroBG});
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .experience-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 10;
      font-family: "Jost", system-ui;
      padding: 1rem;
      width: 100%;
      box-shadow: none;
      border-radius: 0.5rem;
    }
    .headingdiv {
      width: fit-content;
      overflow: hidden;
    }
    .experiences {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1.5rem;
      width: 100%;
    }
    .experienceCard {
      width: 48%;
      /* min-height: 75vh; */
      height: fit-content;
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      align-items: start;
      background: rgba(255, 255, 255, 0.2);

      padding: 1rem 2rem;
      border-radius: 1rem;
      overflow: hidden;
      scroll-behavior: visible;
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      color: wheat;
    }
    .experienceCard .title h4 {
      font-size: 1.2rem;
      font-weight: 500;
      font-weight: 600;
    }
    .experienceCard .date p {
      font-size: 0.8rem;
      padding: 0.2rem 1rem;
    }
    .experienceCard .company p {
      font-weight: 700;
      border-bottom: 1px solid wheat;
    }
    .experienceCard .info ul li {
      font-size: 1rem;
      line-height: 1.3rem;
      margin-bottom: 0.5rem;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: fit-content;
    overflow: hidden;
    position: relative;
    background-image: url(${HeroBG});
    padding: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .experience-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 10;
      font-family: "Jost", system-ui;
      padding: 2rem 0rem;
      width: 90%;
      background: none;
      border-radius: 0.5rem;
      box-shadow: none;
    }
    .headingdiv {
      width: fit-content;
      overflow: hidden;
    }
    .experiences {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1.5rem;
      width: 100%;
    }
    .experienceCard {
      width: 100%;
      height:fit-content;
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      align-items: start;
      background: rgba(255, 255, 255, 0.2);
      padding: 1rem 2rem;
      border-radius: 1rem;
    
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      color: wheat;
    }
    .experienceCard .title h4 {
      font-size: 1.2rem;
      font-weight: 500;
      font-weight: 600;
    }
    .experienceCard .date p {
      font-size: 0.8rem;
      padding: 0.2rem 1rem;
    }
    .experienceCard .company p {
      font-weight: 700;
      border-bottom: 1px solid wheat;
    }
    .experienceCard .info ul li {
      font-size: .8rem;
      line-height: 1.3rem;
      margin-bottom: 0.5rem;
    }
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    height: fit-content;
    overflow: hidden;
    position: relative;
    background-image: url(${HeroBG});
    padding: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .experience-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 10;
      font-family: "Jost", system-ui;
      padding: 2rem 0rem;
      width: 90%;
      background: none;
      border-radius: 0.5rem;
      box-shadow: none;
    }
    .headingdiv {
      width: fit-content;
      overflow: hidden;
    }
    .experiences {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1.5rem;
      width: 100%;
    }
    .experienceCard {
      width: 100%;
      height: fit-content;
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      align-items: start;
      background: rgba(255, 255, 255, 0.2);
      padding: 1rem 2rem;
      border-radius: 1rem;
      overflow: hidden;
      scroll-behavior: visible;
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      color: wheat;
    }
    .experienceCard .title h4 {
      font-size: 1rem;
      font-weight: 500;
      font-weight: 600;
    }
    .experienceCard .date p {
      font-size: 0.8rem;
      padding: 0.2rem 1rem;
      font-size: 0.8rem;
    }
    .experienceCard .company p {
      font-weight: 700;
      border-bottom: 1px solid wheat;
      font-size: 0.8rem;
    }
    .experienceCard .info ul li {
      line-height: 1.3rem;
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
    }
  }
  @media screen and (max-width: 350px) {
    width: 100%;
    height: fit-content;
    overflow: hidden;
    position: relative;
    background-image: url(${HeroBG});
    padding: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .experience-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 10;
      font-family: "Jost", system-ui;
      padding: 2rem 0rem;
      width: 90%;
      background: none;
      border-radius: 0.5rem;
      box-shadow: none;
    }
    .headingdiv {
      width: fit-content;
      overflow: hidden;
    }
    .experiences {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1.5rem;
      width: 100%;
    }
    .experienceCard {
      width: 100%;
      height: fit-content;
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      align-items: start;
      background: rgba(255, 255, 255, 0.2);
      padding: 1rem 2rem;
      border-radius: 1rem;
      overflow: hidden;
      scroll-behavior: visible;
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      color: wheat;
    }
    .experienceCard .title h4 {
      font-size: 1rem;
      font-weight: 500;
      font-weight: 600;
    }
    .experienceCard .date p {
      font-size: 0.8rem;
      padding: 0.2rem 1rem;
      font-size: 0.8rem;
    }
    .experienceCard .company p {
      font-weight: 700;
      border-bottom: 1px solid wheat;
      font-size: 0.8rem;
    }
    .experienceCard .info ul li {
      line-height: 1.3rem;
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
    }
  }
`;
export default Experiences;
