import React from "react";
import HeroBG from "../../assets/bg-img.jpg";
import styled from "styled-components";
import { PortfolioData } from "../../assets/portfolioData";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const Projects = () => {




  return (
    <ExperiencePage>
      <div className="overlay"></div>
      <div className="thecontent">
        <div className="portfolio-content">
          <h2 className="heading">Few Projects</h2>
          <div className="cards">
            {PortfolioData.map((data) => (
              <div key={data.id} className="card">
                <Link to={`/project/${data.id}`}>
                  <div
                    className="portfolioCard"
                    data-aos="zoom-in-up"
                    data-aos-delay="100"
                  >
                    <div className="image">
                      <LazyLoadImage
                        src={data?.image}
                        alt="logo"
                        effect="blur"
                      />
                    </div>
                    <p className="cardHead">{data?.title.slice(0, 15)}</p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ExperiencePage>
  );
};

const ExperiencePage = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  overflow: hidden;
  background-image: url(${HeroBG});
  background-size: cover;
  padding: 2rem 10rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
  }
  .thecontent {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 10;
    font-family: "Jost", system-ui;
    padding: 2rem;
    width: 75%;
    border-radius: 0.5rem;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
      5px 5px 5px 4px rgba(2, 2, 2, 0.15);
    .modal {
      display: flex;
    }
    .close-modal {
      display: none;
    }
    .portfolio-content {
      display: flex;
      align-items: center;
      justify-content: start;
      flex-direction: column;
      gap: 1rem;
      background: none;
      box-shadow: none;
      border-radius: 0.5rem;
      padding: 0rem;
      .cards {
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-wrap: wrap;
        .a {
          background: none;
        }
        .card {
          width: 19%;
          height: 10rem;
          border-radius: 0.5rem;
          overflow: hidden;
          box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
            5px 5px 5px 4px rgba(2, 2, 2, 0.15);
          cursor: pointer;
          margin-bottom: 2rem;
          background: rgba(255, 255, 255, 0.2);
          font-family: "Jost", system-ui;
          .portfolioCard {
            width: 100%;
            height: 10rem;
            overflow: hidden;
            border-radius: 0.5rem;
            position: relative;
            background: none;
            /* padding-bottom: 1rem; */
            a {
              color: wheat;
            }
            .image {
              height: 100%;
              width: 100%;
              overflow: hidden;
              border-bottom-left-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
              img {
                object-fit: cover;
                height: 100%;
                width: 100%;
              }
            }
            .cardHead {
              position: absolute;
              bottom: 0.5rem;
              width: 100%;
              text-align: center;
              font-size: 1rem;
              font-weight: 600;
              color:wheat;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1300px) {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    background-image: url(${HeroBG});
    background-size: cover;
    padding: 2rem 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .thecontent {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 10;
      font-family: "Jost", system-ui;
      padding: 2rem;
      width: 90%;
      border-radius: 0.5rem;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);

      .portfolio-content {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        gap: 1rem;
        background: none;
        box-shadow: none;
        border-radius: 0.5rem;
        padding: 0rem;
        .cards {
          width: 100%;
          display: flex;
          align-items: start;
          justify-content: space-between;
          flex-wrap: wrap;
          .a {
            background: none;
          }
          .card {
            width: 19%;
            height: 10rem;
            border-radius: 0.5rem;
            overflow: hidden;
            box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
              5px 5px 5px 4px rgba(2, 2, 2, 0.15);
            cursor: pointer;
            margin-bottom: 2rem;
            background: rgba(255, 255, 255, 0.2);
            font-family: "Jost", system-ui;
            .portfolioCard {
              width: 100%;
              height: 10rem;
              overflow: hidden;
              border-radius: 0.5rem;
              position: relative;
              background: none;
              /* padding-bottom: 1rem; */
              a {
                color: wheat;
              }
              .image {
                height: 100%;
                width: 100%;
                overflow: hidden;
                border-bottom-left-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
                img {
                  object-fit: cover;
                  height: 100%;
                  width: 100%;
                }
              }
              .cardHead {
                position: absolute;
                bottom: 0.5rem;
                width: 100%;
                text-align: center;
                font-size: 1rem;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    background-image: url(${HeroBG});
    background-size: cover;
    padding: 2rem 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .thecontent {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 10;
      font-family: "Jost", system-ui;
      padding: 2rem;
      width: 90%;
      border-radius: 0.5rem;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);

      .portfolio-content {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        gap: 1rem;
        background: none;
        box-shadow: none;
        border-radius: 0.5rem;
        padding: 0rem;
        .cards {
          width: 100%;
          display: flex;
          align-items: start;
          justify-content: space-between;
          flex-wrap: wrap;
          .a {
            background: none;
          }
          .card {
            width: 24%;
            height: 10rem;
            border-radius: 0.5rem;
            overflow: hidden;
            box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
              5px 5px 5px 4px rgba(2, 2, 2, 0.15);
            cursor: pointer;
            margin-bottom: 2rem;
            background: rgba(255, 255, 255, 0.2);
            font-family: "Jost", system-ui;
            .portfolioCard {
              width: 100%;
              height: 10rem;
              overflow: hidden;
              border-radius: 0.5rem;
              position: relative;
              background: none;
              /* padding-bottom: 1rem; */
              a {
                color: wheat;
              }
              .image {
                height: 100%;
                width: 100%;
                overflow: hidden;
                border-bottom-left-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
                img {
                  object-fit: cover;
                  height: 100%;
                  width: 100%;
                }
              }
              .cardHead {
                position: absolute;
                bottom: 0.5rem;
                width: 100%;
                text-align: center;
                font-size: 1rem;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    background-image: url(${HeroBG});
    background-size: cover;
    padding: 2rem 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .thecontent {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 10;
      font-family: "Jost", system-ui;
      padding: 2rem;
      width: 100%;
      border-radius: 0.5rem;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);

      .portfolio-content {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1rem;
        background: none;
        box-shadow: none;
        border-radius: 0.5rem;
        padding: 0rem;
        .cards {
          width: 100%;
          display: flex;
          align-items: start;
          justify-content: space-between;
          flex-wrap: wrap;
          .a {
            background: none;
          }
          .card {
            width: 45%;
            height: 8rem;
            border-radius: 0.5rem;
            overflow: hidden;
            box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
              5px 5px 5px 4px rgba(2, 2, 2, 0.15);
            cursor: pointer;
            margin-bottom: 2rem;
            background: rgba(255, 255, 255, 0.2);
            font-family: "Jost", system-ui;
            .portfolioCard {
              width: 100%;
              height: 8rem;
              overflow: hidden;
              border-radius: 0.5rem;
              position: relative;
              background: none;
              /* padding-bottom: 1rem; */
              a {
                color: wheat;
              }
              .image {
                height: 100%;
                width: 100%;
                overflow: hidden;
                border-bottom-left-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
                img {
                  object-fit: cover;
                  height: 100%;
                  width: 100%;
                }
              }
              .cardHead {
                position: absolute;
                bottom: 0.5rem;
                width: 100%;
                text-align: center;
                font-size: 1rem;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    background-image: url(${HeroBG});
    background-size: cover;
    padding: 2rem 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .thecontent {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 10;
      font-family: "Jost", system-ui;
      padding: 1rem;
      width: 100%;
      border-radius: 0.5rem;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);

      .portfolio-content {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1rem;
        background: none;
        box-shadow: none;
        border-radius: 0.5rem;
        padding: 0rem;
        .cards {
          width: 100%;
          display: flex;
          align-items: start;
          justify-content: space-between;
          flex-wrap: wrap;
          .a {
            background: none;
          }
          .card {
            width: 45%;
            height: 8rem;
            border-radius: 0.5rem;
            overflow: hidden;
            box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
              5px 5px 5px 4px rgba(2, 2, 2, 0.15);
            cursor: pointer;
            margin-bottom: 2rem;
            background: rgba(255, 255, 255, 0.2);
            font-family: "Jost", system-ui;
            .portfolioCard {
              width: 100%;
              height: 8rem;
              overflow: hidden;
              border-radius: 0.5rem;
              position: relative;
              background: none;
              /* padding-bottom: 1rem; */
              a {
                color: wheat;
              }
              .image {
                height: 100%;
                width: 100%;
                overflow: hidden;
                border-bottom-left-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
                img {
                  object-fit: cover;
                  height: 100%;
                  width: 100%;
                }
              }
              .cardHead {
                position: absolute;
                bottom: 0.5rem;
                width: 100%;
                text-align: center;
                font-size: 1rem;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
  }
`;

export default Projects;
