import React from "react";
import styled from "styled-components";
import Header from "./MainLayout/Header";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <TheMainLayout>
      <div className="header">
        <Header />
      </div>
      <div className="outLet">
        <Outlet />
      </div>
    </TheMainLayout>
  );
};
const TheMainLayout = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    z-index: 10;
    padding: 1rem 10rem;
  }
  .outLet {
    min-height: 70vh;
    height: fit-content;
  }
  @media screen and (max-width: 1200px) {
    position: relative;
    width: 100%;
    height: fit-content;
    .header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: fit-content;
      z-index: 10;
      padding: 0rem;
    }
    .outLet {
      min-height: 70vh;
      height: fit-content;
    }
  }
  @media screen and (max-width: 900px) {
    position: relative;
    width: 100%;
    height: fit-content;
    .header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: fit-content;
      z-index: 10;
      padding: 0rem;
    }
    .outLet {
      min-height: 70vh;
      height: fit-content;
    }
  }
  @media screen and (max-width: 600px) {
    position: relative;
    width: 100%;
    height: fit-content;
    .header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: fit-content;
      z-index: 10;
      padding: 0rem;
    }
    .outLet {
      min-height: 70vh;
      height: fit-content;
    }
  }
  @media screen and (max-width: 420px) {
    position: relative;
    width: 100%;
    height: fit-content;
    .header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: fit-content;
      z-index: 10;
      padding: 0rem;
    }
    .outLet {
      min-height: 70vh;
      height: fit-content;
    }
  }
  @media screen and (max-width: 350px) {
    position: relative;
    width: 100%;
    height: fit-content;
    .header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: fit-content;
      z-index: 10;
      padding: 0rem;
    }
    .outLet {
      min-height: 70vh;
      height: fit-content;
    }
  }
`;
export default Layout;
