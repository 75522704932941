import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    theProject :null
}

const projectSlice = createSlice({
  name: "project",
  initialState,
    reducers: {
      the_clicked_project : (state, action) => {
        state.theProject = action.payload
      }
  }
});

export const {the_clicked_project} = projectSlice.actions

export const projectReducer =  projectSlice.reducer