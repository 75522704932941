import styled from "styled-components";
import CV from "../../assets/myCV.pdf"

const Header = () => {
  return (
    <Navigation>
      <a href={CV} download className="download">
        Download My CV Here
      </a>
    </Navigation>
  );
};

const Navigation = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Jost", system-ui;
font-size:.8rem;
  a {
    color: wheat;
    text-decoration: none;
    border: 1px solid wheat;
    padding: 0.3rem 1rem;
    border-radius: 0.5rem;
  }
`;
export default Header;
