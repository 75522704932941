export const PortfolioData = [
  {
    id: 1,
    title: "Nova Vault ",
    category: "Web App",
    description:
      "This is like a fintech app, majorly for crypto investors. Users can make investment by deposit, Withdraw their investments after profits, Admin can block and unblock users, approve withdrawals, when a user`s deposit time limit is exhausted, the app locks the user out",
    image:
      "https://res.cloudinary.com/obilomania/image/upload/v1723511345/Novaaaaaaaa_u9x4pa.png",
    link: "https://frontend-nova-vault-dot-net.vercel.app/",
    code: "https://github.com/Obilomania/frontend-nova-vault-dotNet",
    techStack: {
      one: "Frontend Made with React Typescript and Styled Components",
      two: "Backend Made with .Net, MsSql and both Hosted on Azure",
      three: "Json Web token for authentication and authorization",
      four: "Redux for state Management",
      five: "RTK query and axios for API calls",
    },
  },
  {
    id: 2,
    title: "Bit-By-Vest",
    category: "Web App",
    description:
      "This is a MERN STACK fintech app like Nova Vault, majorly for crypto investors. Users can make investment by deposit, Withdraw their investments after profits, Admin can block and unblock users, approve withdrawals, when a user`s deposit time limit is exhausted, the app locks the user outs",
    image:
      "https://res.cloudinary.com/obilomania/image/upload/v1725615455/BitByVest_cfx8e8.png",
    link: "https://www.bellabeautyorganics.com/",
    code: "https://github.com/Obilomania/bit-by-vest",
    techStack: {
      one: "Frontend Made with React Typescript and Styled Components",
      two: "Backend Made with Node Js(Express Js), Mongo DB. Application Hosted on Render",
      three: "Json Web token for authentication and authorization",
      four: "Redux for state Management",
      five: "RTK query and axios for API calls",
    },
  },

  {
    id: 3,
    title: "AGC Recruiters",
    category: "Website",
    description:
      "Its a simple HR website made with HTML, CSS and Javascript. Job seekers can go though vacant job openings and apply by uploading their cv and sening it to the admin. I used the Form submit api in accepting file inputs and messages from the frontend and delievering it to the email address of the website admin",
    image:
      "https://res.cloudinary.com/ddykl9wav/image/upload/v1686876438/PORTFOLIO/AGC_cywc0x.png",
    link: "https://agc-recruiters-rebuild.netlify.app/",
    code: "https://github.com/Obilomania/AGC-Rebuild",
    techStack: {
      one: "HTML, CSS & JAvascript",
      two: "Form Submit to submit file to admin email",
      three: "",
      four: "",
      five: "",
    },
  },
  {
    id: 4,
    title: "BlogXIte",
    category: "Web App",
    description:
      "Fullstack blog application, Admin can CRUD blog post but the users need to be authenticated before making comments",
    image:
      "https://res.cloudinary.com/dxlpxh4mv/image/upload/v1706203306/MERN_BLOGXITE_v5slxt.png",
    link: "https://mern-blog-xite.vercel.app/",
    code: "https://github.com/Obilomania/MERN-BlogXite",
    techStack: {
      one: "Frontend Made with React Typescript and Tailwind Css",
      two: "Backend Made with Node Js(Express Js), Mongo DB. Application Hosted on Render",
      three: "Json Web token for authentication and authorization",
      four: "Redux for state Management",
      five: "RTK query and axios for API calls",
    },
  },
  {
    id: 5,
    title: "EarthYverse",
    category: "Web App",
    description:
      "With this application you can know the current rate of the top 10 crypto currencies and also search for the weather condition of any place on this planet. It was built with React, Open weather API and a crypto API from Rapid API",
    image:
      "https://res.cloudinary.com/ddykl9wav/image/upload/v1686876442/PORTFOLIO/EarthyVerse_o1pkgl.png",
    link: "https://earthyverse.vercel.app/",
    code: "https://github.com/Obilomania/eathYverse",
    techStack: {
      one: "React & Styled Components",
      two: "Axios for API calls",
      three: "Coin Gecko API for Crypto and Open Weather App API for weather",
      four: "",
      five: "",
    },
  },
  {
    id: 6,
    title: "Employee Management API",
    category: "Web App",
    description:
      "This API was built with .NET API, do feel free to get the source code from my Github Repo",
    image:
      "https://res.cloudinary.com/ddykl9wav/image/upload/v1686878648/PORTFOLIO/Employee_API_xlonva.png",
    link: "",
    code: "https://github.com/Obilomania/EmployeeManagement_API",
    techStack: {
      one: "ASP.NET Web API",
      two: "",
      three: "",
      four: "",
      five: "",
    },
  },
  {
    id: 7,
    title: "Ecommerce API",
    category: "Web App",
    description:
      "This API was built with .NET API, do feel free to get the source code from my Github Repo",
    image:
      "https://res.cloudinary.com/ddykl9wav/image/upload/v1686878645/PORTFOLIO/EC0MMERCE_api_nbupgl.png",
    link: "",
    code: "https://github.com/Obilomania/RedMango_API",
    techStack: {
      one: "ASP.NET Web API",
      two: "",
      three: "",
      four: "",
      five: "",
    },
  },
  {
    id: 8,
    title: "Typing Speed App",
    category: "Web App",
    description:
      "This APP was built with Html, css and Vanilla Javascript. Users can see the amout of words they can type with a period in time. They can start, stop and reset the timer. do feel free to get the source code from my Github Repo",
    image:
      "https://res.cloudinary.com/ddykl9wav/image/upload/v1686878646/PORTFOLIO/FireShot_Capture_060_-_Typing_Speed_App_-_obilo-typing-speed-app.netlify.app_baqwet.png",
    link: "https://obilo-typing-speed-app.netlify.app/",
    code: "https://github.com/Obilomania/Typing-speed-app",
    techStack: {
      one: "HTML, CSS & JAvascript",
      two: "",
      three: "",
      four: "",
      five: "",
    },
  },
  {
    id: 14,
    title: "Quiz App",
    category: "Web App",
    description:
      "This APP was built React and styled components for styling. i built this app so i can teach my mentees  more about state management",
    image:
      "https://i.ibb.co/6mgSm3s/screencapture-localhost-5173-2024-02-09-21-48-31.png",
    link: "https://quiz-app-tan-nine.vercel.app/",
    code: "https://github.com/Obilomania/quiz-app",
    techStack: {
      one: "React Js and Styled Components",
      two: "",
      three: "",
      four: "",
      five: "",
    },
  },
  {
    id: 9,
    title: "Task Manager",
    category: "Web App",
    description:
      "A MERN Stack application used in teaching my students. Using the local storage for storage of the todo",
    image:
      "https://res.cloudinary.com/ddykl9wav/image/upload/v1686876382/PORTFOLIO/Task_y5o4gd.png",
    link: "https://taskapp-mernstack.onrender.com/",
    code: "https://github.com/Obilomania/frontendMERN-TaskManager",
    techStack: {
      one: "React Js",
      two: "Local Storage",
      three: "",
      four: "",
      five: "",
    },
  },
];
