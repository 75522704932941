import React from 'react'
import styled from 'styled-components'
import HeroSection from './HomeSections/HeroSection'
import About from './HomeSections/About'
import Projects from './HomeSections/Projects'
import Experiences from './HomeSections/Experiences'
import Contact from './HomeSections/Contact'

const Landing = () => {
  return (
      <LandingPage>
      <HeroSection />
      <About />
      <Experiences/>
      <Projects />
      <Contact/>
    </LandingPage>
  )
}

const LandingPage = styled.div`
    
`
export default Landing