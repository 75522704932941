import React, { useRef } from "react";
import { BsGenderMale } from "react-icons/bs";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import HeroBG from "../../assets/bg-img.jpg";

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    if (
      e.target.name.value === "" ||
      e.target.email.value === "" ||
      e.target.message.value === ""
    ) {
      toast.error("All Fields are required");
      return;
    }
    emailjs
      .sendForm(
        "service_qitli57",
        "template_b1r21sw",
        form.current,
        "Z5JmxuUgcyVnMwjvf"
      )
      .then(
        (result) => {
          toast.success("Message sent");
        },
        (error) => {
          toast.error(error.text);
        }
      );
    e.target.reset();
    function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: Adds smooth scrolling animation
      });
    }

    scrollToTop();
  };
  return (
    <ContactPage id="contact">
      <div className="overlay"></div>

      <div className="contact-container">
        <div className="headingDiv">
          <h3 className="heading" data-aos="zoom-in" data-aos-delay="100">
            {" "}
            Contact Obinna
          </h3>{" "}
        </div>
        <div className="contactContent" data-aos="zoom-in" data-aos-delay="100">
          <form ref={form} onSubmit={sendEmail}>
            <div className="topInputs">
              <div className="inputs">
                <label>Full Name :</label>
                <br />
                <input type="text" name="name" placeholder="John Doe" />
              </div>
              <div className="inputs">
                <label>Email :</label>
                <br />
                <input
                  type="text"
                  name="email"
                  placeholder="user@example.com"
                />
              </div>
            </div>
            <div className="textarea">
              <label>Message :</label>
              <br />
              <textarea name="message"></textarea>
            </div>
            <button type="submit">Send message</button>
          </form>
          <div className="right">
            <ul className="">
              <li className="">
                <BsGenderMale /> <span className="text-lightPrimary">Male</span>
              </li>
              <li className="">
                <MdOutlineMarkEmailRead />
                <span className="text-lightPrimary">
                  iloanyaobinna@gmail.com
                </span>
              </li>
              {/* <li className="">
                <MdPhoneInTalk />{" "}
                <span className="text-lightPrimary">+2348033954540</span>
              </li>
              <li className="">
                <MdOutlineAddLocationAlt />
                <span className="text-lightPrimary">Lagos, Nigeria</span>
            </li> */}
            </ul>
          </div>
        </div>
      </div>
    </ContactPage>
  );
};

const ContactPage = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  overflow: hidden;
  background-image: url(${HeroBG});
  background-size: cover;
  padding: 2rem 10rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
  }
  .contact-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 10;
    font-family: "Jost", system-ui;
    padding: 2rem;
    width: 75%;
    border-radius: 0.5rem;
  }
  .headingdiv {
    width: fit-content;
    overflow: hidden;
    height: fit-content;
  }
  .heading {
    /* color: white; */
    font-size: 2rem;
    width: fit-content;
  }
  .contactContent {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
    border-radius: 0.5rem;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
      5px 5px 5px 4px rgba(2, 2, 2, 0.15);
    margin-top: 4rem;
  }
  .contactContent form {
    width: 50%;
    padding: 2rem;
  }
  .contactContent form .topInputs {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: start;
    justify-content: flex-start;
  }
  form label {
    color: wheat;
  }
  form .inputs {
    width: 100%;
    margin-bottom: 1rem;
  }
  form input {
    width: 100%;
    border: 1px solid wheat;
    padding: 0.5rem;
    background: none;
    &::placeholder {
      color: wheat;
    }
    &:focus {
      outline: none;
    }
  }
  form textarea {
    width: 100%;
    height: 20vh;
    resize: none;
    border: 1px solid wheat;
    padding: 0.5rem;
    background: none;
    &::placeholder {
      color: wheat;
    }
    &:focus {
      outline: none;
    }
  }
  form button {
    width: 100%;
    border: none;
    background: wheat;
    padding: 0.5rem 0;
    margin-top: 1rem;
    color: black;
    cursor: pointer;
    transition: 400ms all ease;
    &:hover {
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
    }
  }
  .contactContent .right {
    width: 40%;
    padding: 2rem;
    list-style: none;
    ul {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      li {
        color: wheat;
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }
  }
  @media screen and (max-width: 1300px) {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    background-image: url(${HeroBG});
    background-size: cover;
    padding: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .contact-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 10;
      font-family: "Jost", system-ui;
      padding: 2rem;
      width: 100%;
      border-radius: 0.5rem;
    }
    .headingdiv {
      width: fit-content;
      overflow: hidden;
      height: fit-content;
    }
    .heading {
      /* color: white; */
      font-size: 2rem;
      width: fit-content;
    }
    .contactContent {
      display: flex;
      align-items: start;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2rem;
      width: 100%;
      border-radius: 0.5rem;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      margin-top: 4rem;
    }
    .contactContent form {
      width: 50%;
      padding: 2rem;
    }
    .contactContent form .topInputs {
      width: 100%;
      display: flex;
      gap: 1rem;
      align-items: start;
      justify-content: flex-start;
    }
    form label {
      color: wheat;
    }
    form .inputs {
      width: 100%;
      margin-bottom: 1rem;
    }
    form input {
      width: 100%;
      border: 1px solid wheat;
      padding: 0.5rem;
      background: none;
      &::placeholder {
        color: wheat;
      }
      &:focus {
        outline: none;
      }
    }
    form textarea {
      width: 100%;
      height: 20vh;
      resize: none;
      border: 1px solid wheat;
      padding: 0.5rem;
      background: none;
      &::placeholder {
        color: wheat;
      }
      &:focus {
        outline: none;
      }
    }
    form button {
      width: 100%;
      border: none;
      background: wheat;
      padding: 0.5rem 0;
      margin-top: 1rem;
      color: black;
      cursor: pointer;
      transition: 400ms all ease;
      &:hover {
        box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
          5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      }
    }
    .contactContent .right {
      width: 40%;
      padding: 2rem;
      list-style: none;
      ul {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        li {
          color: wheat;
          display: flex;
          align-items: center;
          gap: 1rem;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 900px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    background-image: url(${HeroBG});
    background-size: cover;
    padding: 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .contact-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 10;
      font-family: "Jost", system-ui;
      padding: 2rem 1rem;
      width: 100%;
      border-radius: 0.5rem;
    }
    .headingdiv {
      width: fit-content;
      overflow: hidden;
      height: fit-content;
    }
    .heading {
      /* color: white; */
      font-size: 2rem;
      width: fit-content;
    }
    .contactContent {
      display: flex;
      align-items: start;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 2rem;
      width: 100%;
      border-radius: 0.5rem;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      margin-top: 4rem;
    }
    .contactContent form {
      width: 100%;
      padding: 2rem;
    }
    .contactContent form .topInputs {
      width: 100%;
      display: flex;
      gap: 0rem;
      align-items: start;
      flex-direction: column;
      justify-content: flex-start;
    }
    form label {
      color: wheat;
    }
    form .inputs {
      width: 100%;
      margin-bottom: 1rem;
    }
    form input {
      width: 100%;
      border: 1px solid wheat;
      padding: 0.5rem;
      background: none;
      &::placeholder {
        color: wheat;
      }
      &:focus {
        outline: none;
      }
    }
    form textarea {
      width: 100%;
      height: 20vh;
      resize: none;
      border: 1px solid wheat;
      padding: 0.5rem;
      background: none;
      &::placeholder {
        color: wheat;
      }
      &:focus {
        outline: none;
      }
    }
    form button {
      width: 100%;
      border: none;
      background: wheat;
      padding: 0.5rem 0;
      margin-top: 1rem;
      color: black;
      cursor: pointer;
      transition: 400ms all ease;
      &:hover {
        box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
          5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      }
    }
    .contactContent .right {
      width: 100%;
      padding: 2rem;
      list-style: none;
      ul {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        li {
          color: wheat;
          display: flex;
          align-items: center;
          gap: 1rem;
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    background-image: url(${HeroBG});
    background-size: cover;
    padding: 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .contact-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 10;
      font-family: "Jost", system-ui;
      padding: 2rem 1rem;
      width: 100%;
      border-radius: 0.5rem;
    }
    .headingdiv {
      width: fit-content;
      overflow: hidden;
      height: fit-content;
    }
    .heading {
      /* color: white; */
      font-size: 2rem;
      width: fit-content;
    }
    .contactContent {
      display: flex;
      align-items: start;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 2rem;
      width: 100%;
      border-radius: 0.5rem;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      margin-top: 4rem;
    }
    .contactContent form {
      width: 100%;
      padding: 2rem;
    }
    .contactContent form .topInputs {
      width: 100%;
      display: flex;
      gap: 0rem;
      align-items: start;
      flex-direction: column;
      justify-content: flex-start;
    }
    form label {
      color: wheat;
    }
    form .inputs {
      width: 100%;
      margin-bottom: 1rem;
    }
    form input {
      width: 100%;
      border: 1px solid wheat;
      padding: 0.5rem;
      background: none;
      &::placeholder {
        color: wheat;
      }
      &:focus {
        outline: none;
      }
    }
    form textarea {
      width: 100%;
      height: 20vh;
      resize: none;
      border: 1px solid wheat;
      padding: 0.5rem;
      background: none;
      &::placeholder {
        color: wheat;
      }
      &:focus {
        outline: none;
      }
    }
    form button {
      width: 100%;
      border: none;
      background: wheat;
      padding: 0.5rem 0;
      margin-top: 1rem;
      color: black;
      cursor: pointer;
      transition: 400ms all ease;
      &:hover {
        box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
          5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      }
    }
    .contactContent .right {
      width: 100%;
      padding: 2rem;
      list-style: none;
      ul {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        li {
          color: wheat;
          display: flex;
          align-items: center;
          gap: 1rem;
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
  }
`;
export default Contact;
