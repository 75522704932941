import React from 'react'
import styled from 'styled-components'
import HeroBG from "../assets/bg-img.jpg";
import { Link, useParams } from 'react-router-dom';
import { PortfolioData } from '../assets/portfolioData';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const ProjectDetail = () => {
     const { id } = useParams();
     const theProject = PortfolioData.find((x) => x.id == id);
     const techStack = Object.values(theProject?.techStack);
  return (
    <TheProjectDetail>
      <div className="overlay"></div>
      <div className="detail-content">
        <div className="content-container">
          <div className="top">
            <LazyLoadImage src={theProject?.image} alt="" />
          </div>
          <h2 className="middle">{theProject?.title}</h2>
          <div className="bottom">
            <div className="bottom-content">
              <div className="left">
                <p>
                  <span>DESCRIPTION</span> : {theProject?.description}
                </p>
              </div>
              <div className="right">
                <p>
                  <span>TECH STACK</span>
                </p>
                <ul>
                  {techStack
                    .filter((tech) => tech) // Filter out empty strings
                    .map((tech, index) => (
                      <li key={index}>{tech}</li> // Render tech stack as list items
                    ))}
                </ul>
              </div>
            </div>
            <div className="call-to-action">
              <button onClick={() => window.history.back()}>CLOSE</button>
              <Link to={`${theProject?.code}`}>SOURCE CODE</Link>
              <Link to={`${theProject?.link}`} target="_blank">
                LIVE DEMO
              </Link>
            </div>
          </div>
        </div>
      </div>
    </TheProjectDetail>
  );
}


const TheProjectDetail = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  overflow: hidden;
  background-image: url(${HeroBG});
  background-size: cover;
  padding: 2rem 10rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
  }
  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
  }
  .hidden {
    display: none;
  }
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  .image-bg {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      min-height: 100vh;
      height: 100%;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 5;
    background: rgba(0, 0, 0, 0.9);
  }
  .detail-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex;
    font-family: "Jost", system-ui;
    padding: 0 10rem;
    .content-container {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 1rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;
      color: wheat;
      span {
        font-weight: 600;
        font-size: 1.2rem;
        color: var(--secondary);
      }
      .top {
        width: 40%;
        img {
          border-radius: 1rem;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        .bottom-content {
          display: flex;
          align-items: start;
          justify-content: space-between;
          .left {
            width: 48%;
          }
          .right {
            width: 48%;
          }
        }
        .call-to-action {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2rem;
          a {
            text-decoration: none;
            color: wheat;
            border: 1px solid wheat;
            border-radius: 0.2rem;
            padding: 0.5rem 1rem;
            transition:  400ms all ease-in-out;
            background: none;
          
          }
          button {
            text-decoration: none;
            color: wheat;
            border: 1px solid wheat;
            border-radius: 0.2rem;
            padding: 0.8rem 2rem;
            transition:  400ms all ease-in-out;
            background: none;
            cursor: pointer;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    .image-bg {
      width: 100%;
      height: 100vh;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        min-height: 100vh;
        height: 100%;
      }
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      z-index: 5;
      background: rgba(0, 0, 0, 0.9);
    }
    .detail-content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: flex;
      padding: 0rem 1rem 1rem 1rem;
      .content-container {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 1rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        color: wheat;
        span {
          font-weight: 600;
          font-size: 1rem;
          color: var(--secondary);
        }
        .top {
          width: 100%;
          img {
            border-radius: 1rem;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .bottom {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 1rem;
          .bottom-content {
            display: flex;
            align-items: start;
            justify-content: space-between;
            flex-direction: column;
            .left {
              width: 100%;
              p {
                font-size: 0.8rem;
              }
            }
            .right {
              width: 100%;
              padding: 1rem;
              p {
                font-size: 0.8rem;
              }
            }
          }
          .call-to-action {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 2rem;
            width: 100%;
            a {
              width: 100%;
              text-align: center;
              text-decoration: none;
              color: wheat;
              border: 1px solid wheat;
              border-radius: 0.2rem;
              padding: 0.5rem 1rem;
              transition:  400ms all ease-in-out;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
  @media screen and (max-height: 700px) {
    width: 100%;
    height: fit-content;
    min-height: 170vh;
    overflow: hidden;
    position: relative;
    .image-bg {
      width: 100%;
      height: 170vh;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        min-height: 170vh;
        height: 100%;
      }
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 170vh;
      width: 100%;
      z-index: 5;
      background: rgba(0, 0, 0, 0.9);
    }
    .detail-content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 170vh;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0rem 1rem 1rem 1rem;
      .content-container {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 1rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        color: wheat;
        span {
          font-weight: 600;
          font-size: 1rem;
          color: var(--secondary);
        }
        .top {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            margin: auto;
            border-radius: 1rem;
            width: 50%;
            height: 100%;
            object-fit: cover;
          }
        }
        .bottom {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 1rem;
          .bottom-content {
            display: flex;
            align-items: start;
            justify-content: space-between;
            flex-direction: column;
            .left {
              width: 100%;
              p {
                font-size: 0.8rem;
              }
            }
            .right {
              width: 100%;
              padding: 1rem;
              p {
                font-size: 0.8rem;
              }
            }
          }
          .call-to-action {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 2rem;
            width: 100%;
            a {
              width: 100%;
              text-align: center;
              text-decoration: none;
              color: wheat;
              border: 1px solid wheat;
              border-radius: 0.2rem;
              padding: 0.5rem 1rem;
              transition: 400ms all ease-in-out;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    .image-bg {
      width: 100%;
      height: 100vh;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        min-height: 100vh;
        height: 100%;
      }
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      z-index: 5;
      background: rgba(0, 0, 0, 0.9);
    }
    .detail-content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: flex;
      padding: 0rem 1rem 1rem 1rem;
      .content-container {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 1rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        color: wheat;
        span {
          font-weight: 600;
          font-size: 1rem;
          color: var(--secondary);
        }
        .top {
          width: 100%;
          img {
            border-radius: 1rem;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .bottom {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 1rem;
          .bottom-content {
            display: flex;
            align-items: start;
            justify-content: space-between;
            flex-direction: column;
            font-size: 0.8rem;
            .left {
              width: 100%;
              p {
              }
            }
            .right {
              width: 100%;
              padding: 1rem;
              p {
                font-size: 0.8rem;
              }
            }
          }
          .call-to-action {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 2rem;
            width: 100%;
            a {
              width: 100%;
              text-align: center;
              text-decoration: none;
              color: wheat;
              border: 1px solid wheat;
              border-radius: 0.2rem;
              padding: 0.5rem 1rem;
              transition:  400ms all ease-in-out;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: fit-content;
    min-height: 130vh;
    overflow: hidden;
    position: relative;
    .image-bg {
      width: 100%;
      height: 130vh;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        min-height: 130vh;
        height: 100%;
      }
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 130vh;
      width: 100%;
      z-index: 5;
      background: rgba(0, 0, 0, 0.9);
    }
    .detail-content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 130vh;
      display: flex;
      align-items: center;
      justify-content: flex;
      padding: 0 1rem;
      .content-container {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 1rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        color: wheat;
        span {
          font-weight: 600;
          font-size: 1rem;
          color: var(--secondary);
        }
        .top {
          width: 100%;
          img {
            border-radius: 1rem;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .bottom {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 1rem;
          .bottom-content {
            display: flex;
            align-items: start;
            justify-content: space-between;
            flex-direction: column;
            font-size: 0.8rem;
            .left {
              width: 100%;
              p {
                font-size: 0.8rem;
              }
            }
            .right {
              width: 100%;
              padding: 1rem;
              p {
                font-size: 0.8rem;
              }
            }
          }
          .call-to-action {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 2rem;
            width: 100%;
            a {
              width: 100%;
              text-align: center;
              text-decoration: none;
              color: wheat;
              border: 1px solid wheat;
              border-radius: 0.2rem;
              padding: 0.5rem 1rem;
              transition:  400ms all ease-in-out;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    height: fit-content;
    min-height: 130vh;
    overflow: hidden;
    position: relative;
    .image-bg {
      width: 100%;
      height: 130vh;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        min-height: 130vh;
        height: 100%;
      }
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 130vh;
      width: 100%;
      z-index: 5;
      background: rgba(0, 0, 0, 0.9);
    }
    .detail-content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 130vh;
      display: flex;
      align-items: center;
      justify-content: flex;
      padding: 0 1rem;
      .content-container {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 1rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        color: wheat;
        span {
          font-weight: 600;
          font-size: 1rem;
          color: var(--secondary);
        }
        .top {
          width: 100%;
          img {
            border-radius: 1rem;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .bottom {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 1rem;
          .bottom-content {
            display: flex;
            align-items: start;
            justify-content: space-between;
            flex-direction: column;
            font-size: 0.8rem;
            .left {
              width: 100%;
              p {
              }
            }
            .right {
              width: 100%;
              padding: 1rem;
              p {
                font-size: 0.8rem;
              }
            }
          }
          .call-to-action {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 2rem;
            width: 100%;
            a {
              width: 100%;
              text-align: center;
              text-decoration: none;
              color: wheat;
              border: 1px solid wheat;
              border-radius: 0.2rem;
              padding: 0.5rem 1rem;
              transition:  400ms all ease-in-out;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
`;
export default ProjectDetail